import { elementTools } from '@joint/core';

const AddText = (elementView, setModalVisible, setElementType, textEditorRef) => {

    return new elementTools.Button({
      markup: [{
        tagName: 'circle',
        selector: 'button',
        attributes: {
            'r': 7,
            'fill': '#001DFF',
            'cursor': 'pointer'
        }
    }, {
        tagName: 'path',
        selector: 'icon',
        attributes: {
            'd': 'M -2 4 2 4 M 0 3 0 0 M -2 -1 1 -1 M -1 -4 1 -4',
            'fill': 'none',
            'stroke': '#FFFFFF',
            'stroke-width': 2,
            'pointer-events': 'none'
        }
    }],
    x: 0, // fix the button at bottom left corner of the shape
    y: '100%',
    offset: {
            x: 0,
            y: 0
        },
        rotate: true,
      action: function (e){
          textEditorRef.current.blur();
          const elementType = elementView.model.get('elementtype');
          const textLabel = elementView.model.attr('label/text')
          const objectID = elementView.model.get('id');
        
          // pass unqiue objectID to store information of eleement in redux state
          setElementType(elementType);
          setModalVisible(prevState => ({
            ...prevState,
            visibility: true,
            text: textLabel,
            objectID: objectID
          }));
      }
    });
  };

export default AddText;