const getSchema = (elementType, inputSchema) => {
  
  const Schemas = {
    MIE: inputSchema,
    KE: inputSchema,
    AOP: inputSchema,
    AO: inputSchema,
  };
  return Schemas[elementType];  

};

export { getSchema };