import { useEffect, useRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import { createRoot } from 'react-dom/client';
import { GiPaintBrush } from "react-icons/gi";
import tippy from 'tippy.js';


function Stroke({ onChange, }) {
  const [selectedColor, setSelectedColor] = useState("#000000");
  const strokRef = useRef(null);
  const color = ["#000000", "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#607d8b"]

const styleName = "Stroke"
  const handleColorSelect = (color) => {
    setSelectedColor(color.hex);
    onChange(color.hex);
  };


  const renderTippyContent = () => {
    return (
      <CirclePicker
    onChangeComplete={handleColorSelect}
    color={selectedColor}
    colors={color}
  />
    );
  };

  /**
   * Create a div to hold the tippy content only once
   */


  useEffect(()=>{

    if (strokRef.current) {


      const tippyContentDiv = document.createElement('div');
      tippyContentDiv.classList.add('element-pallete'); // Add your CSS class here
      document.body.appendChild(tippyContentDiv);
      createRoot(tippyContentDiv).render(renderTippyContent());

      tippy(strokRef.current, {
        content: tippyContentDiv,
        allowHTML: true,
        interactive: true,
        arrow: true,
        trigger: 'click',
        placement: 'right',
        theme: 'elpalette'
      });

    }
  },[strokRef])
 
 
  return (
<div ref={strokRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            border: '1px solid transparent',
            borderRadius: '3px',
            width: '100%',
            margin: '3px',
            transition: 'box-shadow 0.3s ease'
          }}>
           <GiPaintBrush></GiPaintBrush>
           <span style={{ marginLeft: '5px' }}>{styleName}</span>
    </div>    
  );
}

export default Stroke;
