import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { FaPalette } from "react-icons/fa";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import Fill from './Fill';
import Fonts from './Fonts';
import Roughness from './Roughness';
import Stroke from './Stroke';

function DesignPalette({
  onFillChange,
  onFontChange,
  onStrokeChange,
  onRoughChange,
  style
}) {
  const paletteRef = useRef(null);

  const components = [
    { component: Fill, key: 'fill', prop: onFillChange },
    { component: Stroke, key: 'stroke', prop: onStrokeChange },
    { component: Fonts, key: 'font', prop: onFontChange },
    { component: Roughness, key: 'roughness', prop: onRoughChange },
  ];

  const createTippyContent = () => {
    return (
      <table>
        <tbody>
          {components.map(({ component: Component, key, prop }) => (
            <tr className='style-element' key={key} style={{ cursor: 'pointer', margin: '5px' }}>
              <td><Component onChange={prop} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    if (paletteRef.current) {
      const tippyContentDiv = document.createElement('div');
      tippyContentDiv.classList.add('design-pallete');
      document.body.appendChild(tippyContentDiv);
      ReactDOM.createRoot(tippyContentDiv).render(createTippyContent());

      tippy(paletteRef.current, {
        content: tippyContentDiv,
        className: 'tippy-content',
        allowHTML: true,
        interactive: true,
        arrow: false,
        trigger: 'click',
        placement: 'top',
        theme: 'bgpalette',
      });
    }
  }, [paletteRef]);

  return (
    <div ref={paletteRef} style={style}>
      <FaPalette />
    </div>
  );
}

export default DesignPalette;
