const AddLabel = (elementView, textEditorRef) => {
    const model = elementView.model;
    const textEditor = textEditorRef.current;
    const initialLabelText = model.attr('label/text');
    const ptrattr = model.attr('pointers');
    const bdrattr = model.attr('border');
    
    textEditor.style.left = `0`;
    textEditor.style.top = `0`;

    // they'll take as much space as is necessary
    // textEditor.style.width = `${size.width / 150}px`;
    // textEditor.style.height = `${size.height/100}px`;
    textEditor.style.display = 'block';

    // elementView.highlight();   //highlihgts the boundary instead
    model.attr({
      pointers: {
        stroke: 'red',
        strokeWidth: 3,
        fill: 'white',
      },
      border: {
        stroke: 'yellow',
      },
    });
    
    textEditor.textContent = initialLabelText;

    const onBlur = () => {
        const finalText = textEditor.textContent;
        model.attr('label/text', finalText);
        textEditor.style.display = 'none';
        textEditor.removeEventListener('blur', onBlur);
        textEditor.removeEventListener('input', onInput);
        textEditor.removeEventListener('keydown', onKeyDown);

        // elementView.unhighlight();
        model.attr({
          border: {
            stroke: bdrattr.stroke,
          },
          pointers: {
            fill: ptrattr.fill,
            stroke: 'none',
            strokeWidth: 2,
          },
        });
    };

    const onInput = () => {
        let currentText = textEditor.textContent;
        let newText = currentText.replace(/\D/g, '').slice(0, 5);

        // Preserve 'elementtype'
        let prefix = initialLabelText.replace(/\d/g, '');
        textEditor.textContent = prefix + newText;

        // Preserve caret position
        const selection = window.getSelection();
        const range = document.createRange();
        if (textEditor.childNodes.length > 0) {
            range.setStart(textEditor.childNodes[0], textEditor.textContent.length);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
        }

        model.attr('label/text', textEditor.textContent);
    };

    const onKeyDown = (event) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
        if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        } else {
            // Delay execution to allow input to register
            setTimeout(onInput, 0);
        }
    };

    const setCaretToEnd = () => {
        const selection = window.getSelection();
        const range = document.createRange();
        if (textEditor.childNodes.length > 0) {
            range.setStart(textEditor.childNodes[0], textEditor.textContent.length);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    textEditor.addEventListener('blur', onBlur);
    textEditor.addEventListener('input', onInput);
    textEditor.addEventListener('keydown', onKeyDown);
    textEditor.focus();
    setCaretToEnd();
  };

export default AddLabel;