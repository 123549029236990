import { useEffect, useRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import { createRoot } from 'react-dom/client';
import { GiLargePaintBrush } from "react-icons/gi";


import tippy from 'tippy.js';

function Fill({ onChange}) {
  const [selectedColor, setSelectedColor] = useState("#FFFFFF");

  const fillRef = useRef(null);
  const styleName = "Fill"

  const colors = ["#FFFFFF",
                "#f44336",
                "#e91e63", 
                "#9c27b0", 
                "#673ab7", 
                "#3f51b5", 
                "#2196f3", 
                "#03a9f4", 
                "#00bcd4", 
                "#009688", 
                "#4caf50", 
                "#8bc34a", 
                "#cddc39", 
                "#ffeb3b", 
                "#ffc107", 
                "#ff9800", 
                "#ff5722", 
                "#607d8b"]

  const handleColorSelect = (color) => {
    setSelectedColor(color.hex);
    onChange(color.hex);
  };

  const renderTippyContent = () => {
    return (
      <CirclePicker
    onChangeComplete={handleColorSelect}
    color={selectedColor}
    colors={colors}
  />
    );
  };

  

 

  useEffect(()=>{

    if (fillRef.current) {

      const tippyContentDiv = document.createElement('div');
      tippyContentDiv.classList.add('element-pallete'); // Add your CSS class here
      document.body.appendChild(tippyContentDiv);
      createRoot(tippyContentDiv).render(renderTippyContent());

      tippy(fillRef.current, {
        content: tippyContentDiv,
        allowHTML: true,
        interactive: true,
        arrow: true,
        trigger: 'click',
        placement: 'right',
        theme: 'elpalette'
      });

    }
  },[fillRef])
 
  return (
    <div ref={fillRef}
    style={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      border: '1px solid transparent',
      borderRadius: '3px',
      width: '100%',
      margin: '3px',
      transition: 'box-shadow 0.3s ease'
    }}>
           <GiLargePaintBrush />
           <span style={{ marginLeft: '5px' }}>{styleName}</span>

    </div>
  );
}

export default Fill;