const styles = {
    overlay: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      right: 10,
      width: '40%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'right',
    },
    modal: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '20px',
      position: 'relative',
      //width: '80%',
      maxHeight: '80%',
      overflowY: 'auto',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      display: 'block',
      marginLeft: 'auto',
      padding: '8px 16px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    formButton: {
      padding: '10px 20px',
      backgroundColor: 'transparent',
      color: 'black',
      border: '1px solid gray',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '20px',
    },
  };

export default styles;