import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { FaBars, FaFileExport, FaFolderOpen, FaSave } from "react-icons/fa";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // Import Tippy.js CSS
import ORCIDAuth from '../auth/ORCIDAuth';

function Menu({onOptionClick}) {
    const burgerRef = useRef(null);

    const options = [
        { name: "Open", icon: <FaFolderOpen />, command: "open" },
        { name: "Save", icon: <FaSave />, command: "save" },
        { name: "Export Image", icon: <FaFileExport />,command: "export" },
    ];

    const renderTippyContent = () => {
        return (
            <div>
                {options.map((opt, index) => (
                    <div className="option-item" key={index} onClick={()=>{onOptionClick(opt.command)}} >
                        {opt.icon}
                        <span style={{ marginLeft: '5px' }}>{opt.name}</span>
                    </div>
                ))}
            <hr style={{backgroundColor:"lightgray"}}></hr>
                <ORCIDAuth></ORCIDAuth>
            </div>
        );
    };

    useEffect(() => {
        if (burgerRef.current) {
            const tippyContentDiv = document.createElement('div');
            tippyContentDiv.classList.add('design-pallete'); // Add your CSS class here
            document.body.appendChild(tippyContentDiv);
            ReactDOM.createRoot(tippyContentDiv).render(renderTippyContent());

            tippy(burgerRef.current, {
                content: tippyContentDiv,
                allowHTML: true,
                interactive: true,
                arrow: false,
                trigger: 'click',
                placement: 'top',
                theme: 'bgpalette',
            });
        }
    }, [burgerRef]);

    return (
        <div ref={burgerRef}>
            <FaBars />
        </div>
    );
}

export default Menu;
