import './orcid-widget.css';
import OAuth2Login from 'react-simple-oauth2-login';

function ORCIDAuth() {


function onSc(response) {
    console.log(response);
    const code = response.code

    // Send the access token to the backend
    fetch('https://api.aopsketchpad.insilicohub.org/auth/callback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code:code })
    })
    .then(response => console.log(response.json()))


}



const onSuccess = response => {
    onSc(response)};
const onFailure = response => onSc(response);

/**
 * After login  return a div with user name and and their ORCID iD
 *  option to logout as well
 *  
 *  
 * 
 * 
 * 
 */


  return (
    <OAuth2Login
    clientId="APP-DGXFGVQEO7ELJ78P"
    responseType="code"
    redirectUri="https://dev.aopsketchpad.insilicohub.org/"
    scope="openid"
    authorizationUrl="https://orcid.org/oauth/authorize"
    onSuccess={onSuccess}
    onFailure={onFailure}
    isCrossOrigin = {false}
    className='orcidAuthButton'>

   <div>
        <table>
            <tr>
                <td><img src="https://orcid.org/sites/default/files/images/orcid_24x24.png" 
                alt="ORCID iD"/></td>
                <td> Login With ORCID</td>
            </tr>
        </table>
   </div>

    </OAuth2Login>
  )
}

export default ORCIDAuth