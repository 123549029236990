import './index.css';
import './App.css';
import Paper from './components/Paper';

function App() {
  return (
    <>
      <Paper />
    </>
  );
}

export default App;