import React, { useRef, useState } from 'react';
import { BsDashLg } from "react-icons/bs";
import {FaCircle,FaLocationArrow, FaSquare, FaTrash} from "react-icons/fa";
import { FaDiamond, FaFont } from "react-icons/fa6";
import { IoHandLeft, IoTriangle } from "react-icons/io5";
import { RxBorderDashed } from "react-icons/rx";
import { SlActionRedo, SlActionUndo } from "react-icons/sl";
import DesignPalette from './stylePaletteComponents/DesignPalette';
import Background from './stylePaletteComponents/Background';
import Menu from './stylePaletteComponents/Menu';
import { Container,Row,Col} from 'react-bootstrap';



function Header({  
                onSelect,
                fileSelectionEvent,
                onFont,
                onStroke,
                onRough,
                onFill,
                onBackground,
            }) {

    const [selectedOption, setSelectedOption] = useState(null);
    const fileInputRef = useRef(null);

    const elements = [
        { icon: FaSquare, label: 'MIE', handler: 'MIE' },
        { icon: FaCircle, label: 'KE', handler: 'KE' },
        { icon: IoTriangle, label: 'AOP', handler: 'AOP' },
        { icon: FaDiamond, label: 'AO', handler: 'AO' },
        { icon: BsDashLg, label: 'Adjacent', handler: 'solidLink', size: 20 },
        { icon: RxBorderDashed, label: 'Non-Adjacent', handler: 'dashedLink' },
        { icon: FaFont, label: '', handler: 'font' },
        { icon: SlActionUndo, label: '', handler: '' },
        { icon: SlActionRedo, label: '', handler: '' },
        { icon: FaTrash, label: '', handler: 'trash' }
    ];

    const handleStroke = (stroke) =>{
        onStroke(stroke);
    }

    const handleBackground = (bg) =>{
        onBackground(bg);
    }

    const handleFill = (fill) =>{
        onFill(fill);
    }

    const handleRough = (rough) =>{
        onRough(rough);
    }

    const handleFont = (font) => {
        onFont(font);
    }

    const handleClick = (type) => {
        /***
         * 
         * To open the file explorer
         */


        if(type === 'open') {
            fileInputRef.current.click(); /** Initiate a click option */
            onSelect(type);
            setSelectedOption(type);
        }else{
            onSelect(type);
            setSelectedOption(type);
        }

      };

      const getIconStyle = (type) => {
        return selectedOption === type
          ? { color: 'gray', fontWeight: 'bold' }
          : {};
      };

      //links should get unhighlighted after *being used once* and should highlight arrow instead.
      //trash should not stay highlisted and should delesect all icons.
      //undo and redo should be designed in a way that it does't deselect what was selected before

    
    const header__icons = {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)',
        color: 'black'
    }



    return(

        <>

        <Container   style={ {
                        position: 'fixed', 
                        padding: '15px',
                        display: 'flex',
                        bottom: 0,
                        alignItems: 'center',
                        left: '50%',
                        transform: 'translateX(-50%)', 
                        width: 'auto',
                        zIndex: 100, /* Higher Xindex than paper */
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        }}>



                        <Row style={header__icons}>

                            {/**panning */}
                            <Col style={getIconStyle('select')} title='panning'>
                                <IoHandLeft  onClick={() => handleClick('select')} />
                            </Col>

                            {/** selection */}
                            <Col style={getIconStyle('')} title='Selection'>
                                <FaLocationArrow onClick={() => handleClick('')}/>
                            </Col>


                            {/** Background color */}
                            <Col title='Background Color'>
                            <Background style={getIconStyle('paperpalette')} onChange={handleBackground}/>
                            </Col>

                            {/** Styling */}
                            <Col style={getIconStyle('elementpalette')} title='Styling'>
                                <DesignPalette onFontChange={handleFont}
                                onFillChange={handleFill} 
                                onStrokeChange={handleStroke} 
                                onRoughChange={handleRough}>
                                </DesignPalette>
                            </Col>

                            {/** AOP elements */}
                            {elements.map((element, index) => (
                                <React.Fragment key={index}>
                                    <Col  style={getIconStyle(element.handler)}  onClick={() => handleClick(element.handler)}>
                                        <element.icon size={element.size} 
                                        title={element.label}/>
                                    </Col>
                                </React.Fragment>
                            ))}




                            {/** Menu */}
                            <Col>
                            
                            <Menu onOptionClick={handleClick}></Menu>
                            
                            </Col>

                           

                        
                        </Row>
          
           
          
           

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={fileSelectionEvent}
            />
        </Container>
        </>
    );
}

export default Header;