import { useEffect, useRef, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { createRoot } from 'react-dom/client';
import { RiPaintFill } from "react-icons/ri";
import tippy from 'tippy.js';



function Background({onChange, style}) {

  const [selectedColor, setSelectedColor] = useState('#EDFEFF');

  const backgroundPalletRef = useRef(null);

  const colors = [ '#FFFFFF',
                    '#FFFFCC',
                    '#FFF9C4',
                    '#FFECB3',
                    '#FFE0B2',
                    '#FFCCBC',
                    '#F8BBD0',
                    '#E1BEE7',
                    '#D1C4E9',
                    '#C5CAE9',
                    '#BBDEFB',
                    '#B3E5FC',
                    '#FFCCFF', 
                    '#CCFFCC'];

  const customStyles = {
    default: {
      input: {
        display: 'none',
      },
      hash: {
        display: 'none',
      },
    },
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    onChange(color);    
  };
  const renderTippyContent = () => {
    return (
      <TwitterPicker styles={customStyles}
                     triangle="hide" colors = {colors}
                     onChangeComplete={(color) => handleColorSelect(color.hex)} />
    );
  };
  
  useEffect(()=>{

    if (backgroundPalletRef.current) {

      const tippyContentDiv = document.createElement('div');
      document.body.appendChild(tippyContentDiv);
      createRoot(tippyContentDiv).render(renderTippyContent());

      tippy(backgroundPalletRef.current, {
        content: tippyContentDiv,
        allowHTML: true,
        interactive: true,
        arrow: true,
        trigger: 'click',
        placement: 'top',
        theme: 'elpalette'
      });

    }
  },[backgroundPalletRef])
 

  return <div ref={backgroundPalletRef} style={style}>
  <RiPaintFill />
</div>

  
}

export default Background;