import { useEffect, useState,useCallback } from 'react';
import Form from '@rjsf/semantic-ui';
import validator from '@rjsf/validator-ajv8';
import { useDispatch,useSelector } from 'react-redux'
import { storeFormData } from '../../redux/slices/form/form'
import extractTemplateSchema from './utils/extractTemplateData';
import FetchTemplate from './utils/fetchTemplate';
import GenerateSchema from "./utils/GenerateSchema";
import { getSchema } from './utils/schemas';
import styles from './css/ElementModelStyle';
import DraggableDiv from './utils/Dragdiv';
import { throttle } from 'lodash';
import { IoIosCloseCircle } from "react-icons/io";

const ElementModel = ({ visible, elementType, onClose, text,objectID }) => {
  
  const [templateData, setTemplateData] = useState(null);
  const [inputSchema, setInputSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [schema, setSchema] = useState(null);

  /** Redux functions */
  const dispatch = useDispatch()

  /** fetching the form data */
  const formData = useSelector(state => state.form.data.find(item => item.objectID === objectID) || {});


  const templateURL = {
    AOP: 'https://np.knowledgepixels.com/RA6mWzKaFUaK_4JUq_iVGAQyqt2QlzftYCLiQh0kk8cas.jsonld',
    KeyEvent: 'https://np.knowledgepixels.com/RALUzHtS1E_Mba_zwMhhENjZgd23vM0IivF-tUBC633dI.jsonld'
  }

  useEffect(() => {
    if (elementType==='AOP'){
      FetchTemplate(templateURL.AOP).then((data) => {
        setTemplateData(data[1]["@graph"]);
      });
    }
    else{
      FetchTemplate(templateURL.KeyEvent).then((data) => {
        setTemplateData(data[1]["@graph"]);
      });
    }
  }, [elementType]);

  useEffect(() => {
    if (templateData) {
      const { outputSchema, uiSchema } = GenerateSchema(extractTemplateSchema(templateData), text);
      setInputSchema(outputSchema);
      setUiSchema(uiSchema);
      // console.log('extract', JSON.stringify(extractTemplateSchema(templateData), null, 2))
      // console.log('data', JSON.stringify(templateData, null, 2))
      // console.log('schema', JSON.stringify(outputSchema, null, 2))
      // console.log('uischema', JSON.stringify(uiSchema, null, 2))
    }
  }, [templateData, text]);

  useEffect(() => {
    const generatedSchema = getSchema(elementType, inputSchema);
    if (generatedSchema && text) {
      const numberRegex = /\d+/;
      const match = text.match(numberRegex);
      if (match) generatedSchema.properties.ID.default = match[0];
      // else {
      //   generatedSchema.properties.ID.default = 'default';
      // }
    }
    setSchema(generatedSchema);
    setUiSchema(prevUiSchema => ({
      ...prevUiSchema,
      ID: {
        "ui:widget": "text",
        "ui:options": {
          "readonly": true
        }
      }
    }));
  }, [elementType, inputSchema, text]);

  const handleSubmit = () => {
    // Save the form data
    onClose();
  };

  /**
   * Implementing throttling to  reduce
   * the change in state with every key stroke
   **/
  const throttledOnChange = useCallback(throttle((event) => {
    event.formData.objectID = objectID;
    dispatch(storeFormData(JSON.stringify(event.formData, null, 2)));
  }, 1000), [dispatch, objectID]);



  if (!visible) return null;

  return (
    <>
        <DraggableDiv>
        {schema && (
          <div style={{
            position:'relative',
            height:'100%',
            width:'100%',
            overflowY:'auto',
            overflowX:'hidden',
            padding:'10px',
            margin:'10px',
            background:'white',
          }}>

            {/**Div to handle the close button */}
            <div style={{ position: 'absolute', top: '0', right: '0', padding: '10px',zIndex:100000}}>
              <IoIosCloseCircle
              onClick={()=>{onClose()}}              
              />
            </div>

            {/** RJSF form component */}
            <Form
            schema={schema}
            onSubmit={handleSubmit}
            validator={validator}
            uiSchema={uiSchema}
            formData={formData} /** Form data fetched from the redux store */
            onChange={throttledOnChange}
          >
            {/** Publish button */}
            <div style={{ textAlign: 'center' }}>
              <button type="submit" style={styles.formButton}>
                Publish
              </button>
            </div>


          </Form>
          </div>
          
        )}

        </DraggableDiv>
        

</>
  );
};

export default ElementModel;
