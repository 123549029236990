import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { FaFont } from "react-icons/fa";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';  // Import tippy CSS

function Fonts({ onChange }) {

  const [hoveredFont, setHoveredFont] = useState(null);
  const fontRef = useRef(null);

  const handleFont = (font) => {
    if (onChange) onChange(font);
  };

  const fontOptions = [
    { fontFamily: 'Arial', id: 'arial', name: 'Arial' },
    { fontFamily: 'Virgil', id: 'virgil', name: 'Virgil' },
    { fontFamily: 'Cursive', id: 'cursive', name: 'Cursive' },
    { fontFamily: 'Courier', id: 'courier', name: 'Courier' },
    { fontFamily: 'Arial Black', id: 'arialBlack', name: 'Arial B.' },
    { fontFamily: 'Times New Roman', id: 'timesNewRoman', name: 'Times New R.' },
    { fontFamily: 'Lucida Handwriting', id: 'lucidaHandwriting', name: 'Lucida H.' },
  ];

  const renderTippyContent = () => (
    <div>
      {fontOptions.map((font) => {
        const isHovered = hoveredFont === font.id;
        return (
          <div
            key={font.id}
            id={font.id}
            onClick={() => handleFont(font.fontFamily)}
            onMouseEnter={() => setHoveredFont(font.id)}
            onMouseLeave={() => setHoveredFont(null)}
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              margin: '5px',
              padding: '5px',
              boxShadow: isHovered ? '0 0 20px rgba(0, 0, 0, 0.4)' : null,
              transition: 'box-shadow 0.3s ease',
              fontFamily: font.fontFamily,
              cursor: 'pointer'
            }}
          >
            <span>{font.name}</span>
          </div>
        );
      })}
    </div>
  );

  useEffect(() => {
    if (fontRef.current) {
      const tippyContentDiv = document.createElement('div');
      tippyContentDiv.classList.add('element-pallete');
      document.body.appendChild(tippyContentDiv);
      createRoot(tippyContentDiv).render(renderTippyContent());

      tippy(fontRef.current, {
        content: tippyContentDiv,
        allowHTML: true,
        interactive: true,
        arrow: true,
        trigger: 'click',
        placement: 'right',
        theme: 'elpalette'
      });
    }
  }, []);

  return (
    <div
      ref={fontRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px solid transparent',
        borderRadius: '3px',
        width: '100%',
        margin: '3px',
        transition: 'box-shadow 0.3s ease',
        cursor: 'pointer'
      }}
    >
      <FaFont />
      <span style={{ marginLeft: '5px' }}>Fonts</span>
    </div>
  );
}

export default Fonts;
