import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { GiAirZigzag } from "react-icons/gi";
import tippy from 'tippy.js';

function Roughness({ onChange }) {
    const [selectedRough, setSelectedRough] = useState(1);
    const roughRef = useRef(null);
    const tippyInstanceRef = useRef(null);
    const tippyContentDiv = useRef(null);

    const styleName = "Roughness";

    const handleRoughSelect = (event) => {
        const roughValue = parseFloat(event.target.value);
        setSelectedRough(roughValue);
        onChange(roughValue);
    };

    useEffect(() => {
        if (roughRef.current) {
            tippyContentDiv.current = document.createElement('div');
            tippyContentDiv.current.classList.add('element-pallete'); // Add your CSS class here
            document.body.appendChild(tippyContentDiv.current);
            createRoot(tippyContentDiv.current).render(
                <input
                    type='range'
                    min={0}
                    max={5}
                    step={0.1}
                    onChange={handleRoughSelect}
                    value={selectedRough}
                />
            );

            tippyInstanceRef.current = tippy(roughRef.current, {
                content: tippyContentDiv.current,
                allowHTML: true,
                interactive: true,
                arrow: true,
                trigger: 'click',
                placement: 'right',
                theme: 'elpalette'
            });
        }

        return () => {
            if (tippyInstanceRef.current) {
                tippyInstanceRef.current.destroy();
            }
            if (tippyContentDiv.current) {
                tippyContentDiv.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (tippyContentDiv.current) {
            createRoot(tippyContentDiv.current).render(
                <input
                    type='range'
                    min={0}
                    max={5}
                    step={0.1}
                    onChange={handleRoughSelect}
                    value={selectedRough}
                />
            );
        }
    }, [selectedRough]);

    return (
        <div ref={roughRef}
            style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                border: '1px solid transparent',
                borderRadius: '3px',
                width: '100%',
                margin: '3px',
                transition: 'box-shadow 0.3s ease'
            }}>
            <GiAirZigzag />
            <span style={{ marginLeft: '5px' }}>{styleName}</span>
        </div>
    );
}

export default Roughness;
