import {createSlice } from "@reduxjs/toolkit";


const formSlice = createSlice({
    name: "Form",

    initialState : {
        data : [],
        position: {
            x: 0,
            y: 0}
    },

    reducers: {

        storeFormData:(state,action) =>{

            const FormData = JSON.parse(action.payload)
            const index = state.data.findIndex(item => item.objectID === FormData.objectID);

            if (index !== -1) {
                // Replace existing object
                state.data[index] = FormData;
            } else {
                // Add new object
                state.data.push(FormData);
            }
        },

        removeFormData:(state,action) =>{

            /*
             *  
             * Removal should be triggered once we remove the the object from canvas
             * 
             */

            state.data = state.data.filter(item => item.objectID !== action.payload);

            
        },

        setFormPosition: (state,action) =>{

            state.position = action.payload
        }



    },

    
})


const formReducer = formSlice.reducer;
export const {storeFormData, removeFormData,setFormPosition} = formSlice.actions

export default formReducer