import { connectionStrategies, dia, g, linkTools, shapes,util } from '@joint/core';
import { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import rough from 'roughjs';
import svgPanZoom from 'svg-pan-zoom';
import $ from 'jquery';

import ElementModel from './formComponents/ElementModel';
import Header from './Header';
import  Controls from './stylePaletteComponents/Controls';

import AddLabel from './utils/AddLabel';
import AddDoubleClickTools from './utils/AddTools';
import AOPelements from './utils/Elements';
import exportAsPNGorJPEG from './utils/ExportImage';
import { RoughLink, UpdateLinkEndpoints } from './utils/Links';
import { exportGraphAsJSON, importGraphFromJSON } from './utils/SaveGraph';

const Paper = () => {

  /**
     * Note: We are defining custom elements here, to allow the functionality of loading JSON into the graph
     * We need to store the constructor of the shapes and Link, then all the attributes will passed from json to
     * the constructor.
     * https://docs.jointjs.com/learn/features/shapes/cell-namespaces/
     * https://docs.jointjs.com/learn/features/shapes/custom-shapes/

    * Register elements in shapes group
    * https://docs.jointjs.com/learn/features/shapes/custom-shapes/
    * https://docs.jointjs.com/learn/features/shapes/cell-namespaces/
  */

  const [trashClicked, setTrashClicked] = useState(false);
  const [isModalVisible, setModalVisible] = useState(()=>{return {visibility:false,text:''}});
  const [elementType, setElementType] = useState(null);

  /**paper and papermodel ref */
  const paperRef = useRef(null);
  const paperGraph = useRef(null);

  /**style refs */
  const strokeRef = useRef("#000000");
  const fillRef = useRef("#FFFFFF");
  const roughRef = useRef([1]);
  const bgRef = useRef('white');

  /** tool refs */
  const selectedToolRef = useRef(null);
  const selectedFontRef = useRef('Arial');
  const textEditorRef = useRef(null);
  const panZoomRef = useRef(null);

  /** Redux dispatch */
  const dispatch = useDispatch();
  
  const handleExport = (format,bg) => {
    if (paperGraph.current) {
      exportAsPNGorJPEG(paperGraph.current, format,bg);
    }
  };

  const handleSave = () => {
    if (paperGraph.current) {
      exportGraphAsJSON(paperGraph); /** Export of graph as json */
    }
  };

  const removeAllTools = (paper, graph) => {
    const cells = graph.getElements();
    cells.forEach(cell => {
      const view = paper.findViewByModel(cell);
      if (view) {
        view.removeTools();
      }
    });
  };

  useLayoutEffect(() => {
    
    var customNamespace = {
      ...shapes,
      AOPelements, 
      RoughLink
    };

    /*  initializing the graph  */
    const graph = new dia.Graph({},{
      cellNamespace: customNamespace,
    });

    /*   Initializing the paper   */
    const paper = new dia.Paper({
      el: paperRef.current,
      height: "100%", // Pass whole screen as svg area for drawing
      width: "100%",
      gridSize: 1,
      model: graph,
      cellViewNamespace: customNamespace,
      clickThreshold: 5,
      async: true,
      connectionStrategy: connectionStrategies.pinAbsolute,  //multiple links between same elements
      defaultLink: ()=> {
        return new RoughLink({
          attrs: {
              line: {
                  'stroke-dasharray': selectedToolRef.current === 'dashedLink' ? '8' : '0',
              }
          }
      });
      },
      validateMagnet: function (_view, magnet) {
        panZoomInstance.disablePan();
        return magnet.getAttribute('magnet') === 'on-shift' && (selectedToolRef.current==='dashedLink' || selectedToolRef.current==='solidLink'); //makes sure there are no links from nothing
      }
  });

  /*  making paper rough */
  const Rough = rough.svg(paper.svg);
  const borderEl = Rough.rectangle(0, 0,
                        window.innerWidth,
                        window.innerHeight,
                      {
                        stroke: 'transparent',
                    });
  paper.svg.appendChild(borderEl);
  paper.rough = Rough;

  /*  Integrating svg-pan-zoom with paper   */
  const panZoomInstance = svgPanZoom(Rough.svg, {
    dblClickZoomEnabled: false,
    minZoom: 0.1,
    maxZoom: 5,
    onUpdatedCTM: function(matrix) {
      const { a, d, e, f } = matrix;
      const { a: ca, d: cd, e: ce, f: cf } = panZoomInstance.getZoom();
      const translateChanged = e !== ce || f !== cf;
      if (translateChanged) {
        paper.trigger('translate', e - ce, f - cf);
      }
      const scaleChanged = a !== ca || d !== cd;
      if (scaleChanged) {
        paper.trigger('scale', a, d, e, f);
      }
    }
  });

  panZoomRef.current = panZoomInstance;

  /** paper ref */
  paperGraph.current = paper;

  /**
   * 
   * Function to make paper responsive
   */
function scaleContentToFit() {
paper.transformToFitContent({padding: 20, minScaleX: 0.3, minScaleY: 0.3, maxScaleX: 1 , maxScaleY: 1});
}

window.addEventListener('resize', util.debounce(scaleContentToFit), false);
scaleContentToFit();
  



  /*
    // repositioning the controlIcons of svgpanzoom
    const controls = document.querySelector('g[id="svg-pan-zoom-controls"]');
    const svgElement = document.querySelector('svg');
    const svgHeight = svgElement.getBoundingClientRect().height;
    controls.setAttribute('transform', 'translate(10, ' + (svgHeight - 94) + ')');

    // Adjust the size of the control icons
    const controlButtons = controls.querySelectorAll('circle, path');
    controlButtons.forEach(button => {
      button.setAttribute('transform', 'scale(0.8)');
    });
  */

    graph.on({'change:size': (element) => {
      UpdateLinkEndpoints(element, graph);
      },
      /** to prevent element-overlap */
      // 'change:position': (cell) => {
      //     const cellView = paper.findViewByModel(cell);
      //     if (CheckCollision(cellView, graph, paper)) {
      //       cell.set('position', cell.previous('position'));
      //     }
      //   },
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === "Escape") {
          if (textEditorRef.current && textEditorRef.current.style.display === 'block') {
              textEditorRef.current.blur();
          }
          removeAllTools(paper, graph);
      }
  });

    paper.on({
      'link:mouseenter': function (linkView) {
        linkView.addTools(new dia.ToolsView({
          tools: [
            new linkTools.Vertices({ snapRadius: 0 }),
            new linkTools.TargetArrowhead(),
            new linkTools.Remove({
              distance: 20
            })
          ]
        }));
      },
      'link:mouseleave': function (linkView) {
        linkView.removeTools();
      },
      'element:pointerdblclick': function (elementView) {
        AddDoubleClickTools(elementView, textEditorRef, setModalVisible, setElementType,dispatch);
        AddLabel(elementView, textEditorRef);

        setModalVisible(prevState => ({
          ...prevState,
          visibility: false,
          text: prevState.textLabel
        }));
      },
      'element:pointerdown': function () {
        if (selectedToolRef.current!=='select') {
          panZoomInstance.disablePan();
        }
        else{panZoomInstance.enablePan();}
        if (textEditorRef.current.style.display === 'block') textEditorRef.current.blur();
      },
      'blank:pointerdown': function(evt, x, y) {
        if (selectedToolRef.current !=="select"){
          panZoomInstance.disablePan();
          var data = evt.data = {};
          var cell;
          var type = selectedToolRef.current;
          cell = AOPelements.create(type, selectedFontRef,panZoomInstance, selectedToolRef, strokeRef, fillRef, roughRef)
          cell.position(x, y);
          data.x = x;
          data.y = y;
          cell.addTo(this.model);
          data.cell = cell;
        }else{
          panZoomInstance.enablePan();
        }
        textEditorRef.current.blur();
      },
      'blank:pointerclick': function() {
          setModalVisible(false);   //should be handled with more complexity, show a react-toast if there are unsaved changes
      },
      'blank:pointermove': function(evt, x, y) {
        if (selectedToolRef.current !=="select" && selectedToolRef.current !== ''){
          panZoomInstance.disablePan()
          var data = evt.data;
          var cell = data.cell;
          var bbox = new g.Rect(data.x, data.y, x - data.x, y - data.y);
          bbox.normalize();
          if (selectedToolRef.current === 'KE' || selectedToolRef.current === 'MIE' || selectedToolRef.current === 'AO' || selectedToolRef.current === 'AOP'){
          cell.set({
            position: { x: bbox.x, y: bbox.y },
            size: { width: Math.max(bbox.width, 1), height: Math.max(bbox.height, 1) }
          });
        }
      }
      },
      'blank:pointerup': function(evt) {
        if (selectedToolRef.current !== "select") {
          if (selectedToolRef.current === 'KE' || selectedToolRef.current === 'MIE' || selectedToolRef.current === 'AO' || selectedToolRef.current === 'AOP'){
          const data = evt.data;
          const cell = data.cell;
          const size = cell.size();
          if (size.width < 20 || size.height < 20) {
            cell.remove();

          }}
          if (selectedToolRef.current==='dashedLink' || selectedToolRef.current==='solidLink') selectedToolRef.current = '';
        }
        graph.getLinks().forEach(link => {
          const source = link.getSourceElement();
          const target = link.getTargetElement();
          if (!source || !target) {
            link.remove();
          }
        });
      },
      'link:pointerup': function(linkView) {
        const link = linkView.model;
        const source = link.getSourceElement();
        const target = link.getTargetElement();
        if (!source || !target || source === target) {
          link.remove();
        }
      },
    });

    if (selectedToolRef.current === 'trash') {
      graph.clear();
      paperRef.current.style.backgroundColor = "#EDFEFF";
    };

  }, [trashClicked]);

return (
  <>
    <div
      id='paper'
      ref={paperRef}
      style={{
        width: 'auto',
        height: 'auto',
        background: "#EDFEFF",
        overflow:'auto'
      }}
    />

    <Header
      onFont={(font) => {
        selectedFontRef.current = font;
      }}
      onStroke={(stroke) => {
        strokeRef.current = stroke
      }}
      onBackground={(bg) => {
        bgRef.current = bg;
        paperRef.current.style.backgroundColor = bg;
      }} //it'll work fine when paper has complete window size and Header hovers over it
      onFill={(fill) => {
        fillRef.current = fill
      }}
      onRough={(rough) => {
        roughRef.current = rough
      }}
      onSelect={(type) =>{ 
        if(type ==="save"){
          handleSave();
        }
        if (type === 'export') {
          handleExport('png',bgRef.current);
        }
        selectedToolRef.current = type;
        if (type === 'trash'){
          setTrashClicked(state => !state);
        }}}
        /** File selection initiate this */
        fileSelectionEvent={(event =>{
          importGraphFromJSON(paperGraph,event)
        })}
    />

    <div
      ref={textEditorRef}
      className="text-editor"
      contentEditable="true"
    />

    <ElementModel
      visible={isModalVisible.visibility}
      elementType={elementType}
      onClose={() => setModalVisible(false)}
      text={isModalVisible.text}
      objectID={isModalVisible.objectID}
    />

        {/**
     * Zoom Controls
     */}
    <Controls panZoom= {panZoomRef}/>


  </>
);
}

export default Paper;